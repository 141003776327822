
.icon_formal_activate {
  &:hover {
    span {
      color: #202020 !important;
    }
    svg {
      path {
        fill: #202020;
      }
    }
  }
}

.icon_formal_stroke_active {
  &:hover {
    span {
      color: #202020 !important;
    }
    svg {
      path {
        stroke: #202020;
      }
    }
  }
}


.icon_del_activate {
  &:hover {
    span {
      color: #EE4747 !important;
    }
    svg {
      path {
        fill: #EE4747;
      }
    }
  }
}
