.wrapper {
  margin-top: 16px;
  .top_box {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    .top_left {
      color: #202020;
    }
    .top_right {
      color: #4361FF;
      span {
        cursor: pointer;
        &:last-child {
          margin-left: 8px;
        }
      }
    }
  }
}