.code_box {
  color: #4361FF;
  font-size: 16px;
  cursor: pointer;
}
.time_box {
  color: #8C8D96;
  font-size: 16px;
}

.btn_box {
  display: flex;
  .default_btn {
    margin-right: 16px;
  }
  button {
    flex: 1;
    font-size: 14px;
    height: 40px;
    padding: 8px 0;
    font-family: Source Han Sans CN;
    font-weight: 500;
    border-radius: 6px;
  }
}

.rests {
  width: fit-content;
  padding: 4px 10px;
  border-radius: 99px;
  border: 1px solid #ECF0F5;
  background: rgba(0, 0, 0, 0.02);
  margin: 0 auto;
  margin-top: 24px;
  cursor: pointer;
  span {
    margin-left: 4px;
    line-height: normal;
    color: #666;
    font-size: 14px;
  }
}