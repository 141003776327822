.input_box {
  textarea {
    resize: none;
    border-radius: 8px;
    border: none;
    width: 100%;
    min-height: 120px !important;
    outline: 0;
    font-size: 16px;
    color: #202020;
    background: #ECF0F5;
    padding-top: 6px;
    &::placeholder {
      color: #8C8D96;
      font-size: 16px;
      font-family: Microsoft YaHei;
    }
    &:focus {
      box-shadow: 0px 0px 0px 2px #4361FF;
    }
  }
}