.main {
  min-width: 1430px;
  // min-width: 1200px;
  width: 100%;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  .content {
    height: 100%;
    display: flex;
  }
  & * {
    box-sizing: border-box;
  }
}
