:root {
  --text-l1: #2D374C;
  --text-l2: #4A5D82;
  --text-note: #8E9DBA;
  --black-22: #222;
  --brand-l1: #0A78F9;
  --brand-disable: #AACCFF;
  --black-66: #666;
  --alarm-l-1: #E74E4E;
  --outline-l-1: #DDE7FF;
  --LightBack: #F2F6FC;

  --l-1: #4B7BFF;
  --l-4: #F3F7FF;
  --border-radius: 8px;
}

.color-text-l1 {
  color: var(--text-l1);
}

.color-text-note {
  color: var(--text-note);
}

.header_title_text {
  color: var(--text-l1);
  font-family: Microsoft YaHei;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.out_login_text {
  color: var(--alarm-l-1) !important;
  font-family: Microsoft YaHei;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.scrollbar_style {
  /* 定义滚动条样式 */
  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }
  /*定义滚动条轨道 内阴影+圆角*/
  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: transparent;
  }
  /*定义滑块 内阴影+圆角*/
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D8DDEB;
  }
  &::-moz-scrollba {
    width: 4px;
    background: transparent;
  }
  &::-moz-scrollbar-track {
    border-radius: 2px;
    background-color: transparent;
  }
  &::-moz-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D8DDEB;
  }
}

.none_scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.aiq-btn-primary {
  background: linear-gradient(180deg, #4C7EFF 0%, #4361FF 100%);
  color: #fff;
}

.aiq-btn-default {
  color: var(--l-1);
  border: 1.6px solid var(--l-1);
}

.aiq-btn-ghost {
  color: #4361FF !important;
  border-color: #4361FF !important;
}

@mixin overflow_hiding_text($num) {
  display: -webkit-box;
  -webkit-line-clamp: $num;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
