.modal_class {
  :global {
    .ant-modal-title {
      text-align: center;
      color: #202020;
      font-size: 16px;
    }
    .ant-modal-content {
      padding: 16px;
    }
  }
}

.modal_box {
  .it_box {
    margin-top: 16px;
    .it_title {
      color: #202020;
      font-size: 14px;
      margin-bottom: 8px;
    }
  }
  .age_box {
    display: flex;
    justify-content: space-between;
    .age_it {
      padding: 6px 13px;
      border-radius: 4px;
      border: 1px solid #ECF0F5;
      background: #fff;
      color: #8C8D96;
      font-size: 14px;
      cursor: pointer;
    }
    .age_it_activated {
      border-color: #4361FF;
      background: var(--l-4);
      color: #4361FF;
    }
  }
}

.btn_box {
  padding-top: 16px;
  button {
    width: 100%;
    height: 44px;
  }
}