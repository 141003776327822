.agreement_box {
  display: flex;
  font-size: 14px;
  .agreement_left {
    span {
      color: #404040;
      font-family: Microsoft YaHei;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      user-select: none;
      font-size: 14px;
    }
    :global {
      .ant-checkbox-wrapper {
        span {
          padding-inline-end: 0 !important;
        }
      }
    }
  }
  .agreement_right {
    span {
      user-select: none;
      color: #4B7BFF;
      font-family: Microsoft YaHei;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
    }
  }
}
