.login_modal {
  border-radius: 16px;
  :global {
    .ant-modal-content {
      // overflow-x: auto;
      // overflow: hidden;
      border-radius: 24px;
      padding: 0 !important;
      background: #4361FF;
      box-shadow: none;
      // .ant-modal-body {
      //   border-radius: 24px;
      // }
    }
  }
}

.modal_box {
  width: 756px;
  height: 400px;
  background: #4361FF;
  border-radius: 24px;
  position: relative;
  .close_btn {
    position: absolute;
    right: -15px;
    top: -15px;
    cursor: pointer;
  }
  .bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    img {
      vertical-align: bottom;
      position: relative;
      z-index: 2;
      border-radius: 0 0 0 24px;
    }
    .circle {
      position: absolute;
      top: 72px;
      left: -52px;
      width: 522px;
      height: 481px;
      border-radius: 522px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 58.52%);
    }
  }
  .right_box {
    width: 325px;
    height: 368px;
    border-radius: 16px;
    background: #fff;
    position: absolute;
    z-index: 3;
    right: 16px;
    top: 16px;
    padding: 0 16px;
    box-sizing: border-box;
    .title {
      margin: 32px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 12px;
      }
    }
    .title2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 32px 0;
      .title2_text {
        color: #404040;
        font-family: Source Han Sans CN;
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
}