@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  min-width: 1200px;
  overflow-y: hidden;
}

#root {
  /* width: 100vw; */
  /* overflow-x: auto; */
}

#nprogress .bar {
  background: #D0E3FF !important;
} 

.cursor_pointer {
  cursor: pointer;
}
.cursor_not_allowed {
  cursor: not-allowed;
}

.window_content_width {
  max-width: 1200px;
  margin: 0 auto;
}

.filex_align_center {
  display: flex;
  align-items: center;
}

.filex_justify_center {
  display: flex;
  justify-content: center;
}

.filex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filex_space_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.zebra_bg1 {
  background-color: #F9FBFF;
}

.section_margin_top {
  margin-top: 30px;
}

.section_padding {
  padding: 100px 0;
}

.ml10 {
  margin-left: 10px;
}
.ml20 {
  margin-left: 20px;
}
.ml30 {
  margin-left: 30px;
}
.ml40 {
  margin-left: 40px;
}
.ml50 {
  margin-left: 50px;
}