.wrapper {
  margin-top: 16px;
  .top_box {
    display: flex;
    justify-content: space-between;
  }
  .plan_box {
    display: flex;
    .plan_btn {
      padding: 6px 10px 6px 12px;
      border-radius: 6px;
      border: 1px solid #ECF0F5;
      background: #fff;
      cursor: pointer;
      span {
        color: #8C8D96;
        font-size: 14px;
        margin-right: 4px;
      }
    }
    .plan_btn_status1 {
      border-color: var(--l-1);
      background: var(--l-1);
      span {
        color: #fff;
      }
    }
    .plan_btn_status2 {
      border-color: #4361FF;
      background: #F3F7FF;
      span {
        color: #4361FF;
      }
    }
  }
}