.wrapper {
  display: flex;
  .it_box {
    cursor: pointer;
    padding: 6px 10px;
    border-radius: 6px;
    border: 1px solid #ECF0F5;
    background: #fff;
    .it_text {
      font-size: 14px;
      color: #202020;
      margin-right: 8px;
    }
  }
}

.popover_class {
  color: red;
  :global {
    .ant-popover-inner {
      overflow: hidden;
      padding: 0 !important;
      border: 1px solid #ECF0F5 !important;
      box-shadow: 0px 8px 12px 0px rgba(9, 28, 62, 0.05) !important;
    }
  }
}

.menu {
  max-height: 328px;
  overflow-y: auto;
  .menu_it {
    padding: 10px 20px;
    border-bottom: 1px solid #ECF0F5;
    background: #fff;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    &:nth-child(1) {
      color: #8C8D96;
      cursor: initial;
      &:hover {
        background: #fff;
      }
    }
    &:hover {
      background: var(--l-4);
    }
  }
  .menu_it2 {
    display: flex;
    justify-content: space-between;
    .menu_it_text {
      color: #202020;
      font-size: 16px;
      margin-right: 92px;
    }
    .menu_it_pre {
      padding: 2px 5px;
      font-size: 12px;
      color: #999;
      border-radius: 4px;
      border: 1px solid #C4C4C4;
      background: #fff;
    }
    .menu_it_pre_activate {
      border-color: var(--l-1);
      color: var(--l-1);
    }
  }
  .menu_it_activated {
    background: var(--l-4);
    color: #4361FF;
    .menu_it_text {
      color: #4361FF;
    }
  }
}