.wrapper {
  max-width: 360px;
  width: 360px;
  transition: width 0.1s;
  overflow: hidden;
  background: #F4F6FB;
  border-left: 1px solid #ECF0F5;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  .userinfo {
    padding: 12px 16px;
    background: #fff;
    border-radius: var(--border-radius);
    .info_left {
      .avatar {
        margin-right: 12px;
      }
      .username {
        color: #000;
        font-size: 16px;
        padding-right: 12px;
      }
    }
    .info_right {
      span {
        font-size: 14px;
        color: #4361FF;
        cursor: pointer;
      }
    }
  }
  .tag {
    padding: 2px 4px;
    border-radius: 4px;
    border: 1px solid #C4C4C4;
    background: #FCFCFC;
    color: #C4C4C4;
    font-size: 12px;
  }
  .tab_activated {
    border: 1px solid var(--l-1);
    background: #F9FBFF;
    color: var(--l-1);
  }
  .login_btn {
    button {
      width: 100%;
      height: 56px;
      font-size: 18px;
    }
  }
}
.side_unfold {
  width: 360px;
}
.side_packup {
  width: 0;
}