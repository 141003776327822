.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 68%;
}

.code_box {
  color: #4361FF;
  font-size: 16px;
  cursor: pointer;
}

.btn_box {
  display: flex;
  button {
    flex: 1;
    font-size: 14px;
    height: 40px;
    padding: 8px 0;
    font-family: Source Han Sans CN;
    font-weight: 500;
    border-radius: 6px;
  }
}