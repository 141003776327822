.list_box {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  padding-bottom: 0;
}

.empty_box {
  margin-top: 94px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .empty_text {
    margin-top: 16px;
    color: #8C8D96;
    font-size: 14px;
  }
}
