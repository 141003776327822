
.tabs_box {
  background: #fff;
  box-shadow: 0px 8px 12px 0px rgba(9, 28, 62, 0.03);
  position: relative;
  .tab_content {
    display: flex;
    .tab_it {
      flex: 1;
      padding: 16px 0;
      text-align: center;
      cursor: pointer;
      .tab_it_text {
        color: #404040;
        font-size: 16px;
      }
    }
    .activate {
      .tab_it_text {
        color: #4361FF;
      }
    }
  }
  .line {
    transition: transform 0.3s;
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% / 3);
    height: 2px;
    background: #4361FF;
  }
}