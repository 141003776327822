.drawer_class {
  :global {
    .ant-drawer-body {
      padding: 0;
    }
  }
}
.modal_box {
  height: 100%;
  display: flex;
  flex-direction: column;
  .list {
    flex: 1;
    overflow-y: auto;
    .item {
      border-bottom: 1px solid #ECF0F5;
      padding: 16px 24px;
      &:last-child {
        border: none;
      }
      .it_top {
        .it_title {
          color: #202020;
          font-size: 16px;
          font-weight: 500;
        }
        .it_years {
          padding: 2px 6px;
          border-radius: 4px;
          border: 1px solid #4B7BFF;
          background: #F9FBFF;
          color: var(--l-1);
          font-size: 12px;
        }
      }
      .it_bottom {
        margin-top: 12px;
        .desc {
          color: #8C8D96;
          font-size: 14px;
          white-space: nowrap; 
          text-overflow: ellipsis; 
          overflow: hidden; 
          flex: 1;
        }
        .edit {
          padding-left: 12px;
          .edit_it {
            display: flex;
            align-items: center;
            cursor: pointer;
            &:last-child {
              margin-left: 12px;
            }
            span  {
              color: #666;
              font-size: 14px;
              margin-left: 2px;
            }
          }
        }
      }
    }
  }
  .btn_box {
    padding: 24px;
    button {
      width: 100%;
      height: 44px;
    }
  }
}

.empty_box {
  // margin-top: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .empty_text {
    margin-top: 16px;
    color: #8C8D96;
    font-size: 14px;
  }
}