.wrapper {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  .btn {
    height: 44px;
    padding: 10px 32px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      margin-left: 8px;
    }
  }
  .email_btn {
    flex: 1;
    margin-right: 0;
  }
}