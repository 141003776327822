.my_modal {
  text-align: center;
  :global {
    .ant-modal-title {
      font-size: 16px;
      color: #202020;
    }
    .ant-modal-content {
      padding: 16px;
    }
  }
  .other {
    font-size: 14px;
    .other_it {
      width: fit-content;
      margin: 16px auto 0 auto;
      display: flex;
      align-items: center;
      cursor: pointer;
      .circle {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        border: 1px solid #C4C4C4;
        background: #fff;
      }
      .def_text {
        color: #8C8D96;
        margin-left: 6px;
      }
      .other_text {
        color: var(--l-1);
      }
    }
  }
}

.btn_box {
  margin-top: 24px;
  button {
    width: 100%;
    height: 44px;
  }
}
