
.wrapper {
  max-width: 360px;
  width: 360px;
  background: #F4F6FB;
  border-right: 1px solid #ECF0F5;
  transition: width 0.1s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.side_unfold {
  width: 360px;
}
.side_packup {
  width: 0;
}

.pagination_box {
  display: flex;
  justify-content: center;
  padding: 12px 0;
  :global {
    .ant-pagination-prev, .ant-pagination-next {
      width: 32px;
      height: 32px !important;
      margin-inline-end: 0;
      // border-radius: 2px;
      // border-color: #D9D9D9 !important;
      // background: #fff !important;
    }
    .ant-pagination-item {
      margin: 0 4px !important;
      width: 32px;
      height: 32px !important;
      border-radius: 2px;
      border-color: #D9D9D9 !important;
      background: #fff !important;
      a {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 32px;
      }
    }
    .ant-pagination-item-active {
      border-color: #1890FF !important;
      a {
        color: #1890FF;
      }
    }
  }
}