.btn_box {
  margin-top: 22px;
  display: flex;
  button {
    flex: 1;
    font-size: 14px;
    height: 40px;
    padding: 8px 0;
    font-family: Source Han Sans CN;
    font-weight: 500;
    border-radius: 6px;
  }
}