.input_wrapper {
  background: #ECF0F5;
  // border: 1px solid var(--LightBack);
  padding: 0 12px;
  border-radius: 6px;
  height: 40px;
  // 去掉输入框数字的上下箭头默认样式
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.input {
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
  flex: 1;
  font-size: 16px;
  color: #202020;
  line-height: 150%;
  &::placeholder {
    color: #8C8D96;
    font-size: 16px;
  }
}
.disabled_text {
  color: #8C8D96;
}