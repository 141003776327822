@import '@/assets/css/theme.scss';
.list_it {
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  .it_top {
    display: flex;
    justify-content: space-between;
    .it_title {
      color: #202020;
      font-size: 16px;
    }
    .it_time {
      color: #8C8D96;
      font-size: 14px;
    }
  }
  .it_center {
    padding: 8px 0;
    .it_content {
      color: #404040;
      font-size: 14px;
      line-height: 150%;
      @include overflow_hiding_text(4);
    }
  }
  .it_footer {
    margin-top: 8px;
    display: flex;
    justify-content: space-around;
    .footer_it {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .text {
        color: #666;
        font-size: 14px;
        margin-left: 6px;
      }
    }
  }
}