.login_modal {
  text-align: center;
  :global {
    .ant-modal-title {
      font-size: 16px;
      color: #202020;
    }
    .ant-modal-content {
      padding: 16px;
    }
  }
  .desc {
    color: #8C8D96;
    font-size: 14px;
  }
}

.btn_box {
  margin-top: 24px;
  button {
    width: 100%;
    height: 44px;
  }
}
