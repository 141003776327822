.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .my_divider {
    border-block-start: #ECF0F5;
    &::before, &::after {
      transform: initial;
    }
    :global {
      .ant-divider-inner-text {
        color: #8C8D96;
        font-size: 14px;
      }
    }
  }
  .content {
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #ECF0F5;
    flex: 1;
    overflow-y: auto;
  }
  .hint {
    margin: 12px 0;
    font-size: 12px;
    color: #8C8D96;
  }
}