.wrapper {
  padding: 0 16px;
  height: 60px;
  background: linear-gradient(180deg, #4C7EFF 0%, #4361FF 100%);
  box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.20) inset, 0px -3px 0px 0px rgba(0, 0, 0, 0.08) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  .center {
    // flex: 1;
    span {
      font-size: 18px;
      font-weight: 700;
      color: var(--l-4);
    }
  }
  .left, .right {
    display: flex;
    align-items: center;
  }
  .hidden_box {
    height: 36px;
    padding: 0 12px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.10);
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    div {
      transition: transform 0.3s;
    }
    span {
      color: var(--l-4);
      font-size: 16px;
    }
  }
  .hide_right {
    transform: rotate(90deg);
  }
  .hide_left {
    transform: rotate(-90deg);
  }
}