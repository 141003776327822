
.cell {
  padding: 16px 0;
  border-bottom: 1px solid #ECF0F5;
  &:last-child {
    border-bottom: none;
  }
  .cell_left {
    .cell_label {
      color: #202020;
      font-size: 16px;
    }
  }
  .cell_right {
    font-size: 14px;
    color: #999;
  }
}