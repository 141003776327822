.wrapper {
  flex: 1;
  .content {
    height: 100%;
    margin: 0 auto;
    width: 800px;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
  }
}