.my_modal {
  top: 70px;
  min-width: 800px;
  :global {
    .ant-modal-content {
      padding: 16px;
      height: 85vh;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
    .ant-modal-body {
      flex: 1;
      overflow-y: hidden;
    }
    .ant-modal-title {
      text-align: center;
      color: #222;
      font-size: 17px;
    }
  }
  .modal_box {
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #ECF0F5;
    overflow-y: auto;
    height: 95%;
    .content {
      height: 100%;
      outline: 0;
      color: #000;
      font-size: 14px;
      font-family: Source Han Sans CN;
      line-height: 170%;
    }
  }
  .footer_box {
    display: flex;
    justify-content: end;
    .btn {
      height: 44px;
      padding: 10px 32px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}