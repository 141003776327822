.wrapper {
  margin-top: 24px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  .title {
    color: #404040;
    font-size: 14px;
    text-align: center;
  }
  .list {
    flex: 1;
    overflow-y: auto;
    margin-top: 12px;
    padding: 0 16px;
    .list_item {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 28px;
      }
      padding: 16px;
      background: #fff;
      border-radius: var(--border-radius);
      .top {
        .top_left {
          color: #202020;
          font-size: 16px;
          font-weight: 550;
        }
        .top_right {
          color: #8C8D96;
          font-size: 14px;
        }
      }
      .center {
        padding: 8px 0;
        div {
          word-break: break-all;
          font-size: 14px;
          color: #404040;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .footer {
        padding-top: 8px;
        display: flex;
        justify-content: space-around;
        .footer_it {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #666;
          cursor: pointer;
          span {
            margin-left: 6px;
          }
        }
      }
    }
  }
  .empty_box {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .empty_text {
      margin-top: 16px;
      color: #8C8D96;
      font-size: 14px;
    }
  }
}