.wrapper {
  margin-top: 16px;
  display: flex;
  .btn {
    transition: width 0.3s;
    button {
      width: 100%;
      height: 48px;
      font-size: 16px;
    }
  }
  .btn1 {
    width: 0;
    // min-width: 112px;
    // margin-right: 16px;
    // overflow: hidden;
  }
  .btn1_activate {
    width: 112px;
    margin-right: 16px;
  }
  .btn2 {
    flex: 1;
    // button {
    //   &:hover {
    //     background: linear-gradient(180deg, #3E73FB 0%, #3251F7 100%);
    //   }
    // }
  }
}